import {
    Box,
    Paper,
    styled
} from "@mui/material";

import DesktopMenu from "../Desktop";
import MobileMenu from "../Mobile";
import bgImage from "../../../assets/Images/Header/background-header.jpg";

const ItemMenu = styled(Paper)(({theme}) => ({
    backgroundImage: `url(${bgImage})`,
    objectFit: "cover",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    // backgroundColor: theme.palette.common.white[800],
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.common.white,
    margin: theme.spacing(0.6),

}));

function MenuContainer() {

    return (
        <>
            <Box sx={{display: {xs: "none", md: "block"}}}>
                <ItemMenu elevation={8}>
                    <DesktopMenu/>
                </ItemMenu>
            </Box>

            <Box sx={{display: {xs: 'block', md: 'none'}}}>
                <MobileMenu/>
            </Box>
        </>

    )
}

export default MenuContainer

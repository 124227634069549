import {Stack, styled} from "@mui/material";

const StackItem = styled(Stack)(({theme}) => ({
    //general styles
    width: "100%",
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    //styles breakpoints
    [theme.breakpoints.up('xs')]: {
        h3: {
            fontSize: "1.3rem"
        },
        h6: {
            fontSize: "1rem",
        }
    },
    [theme.breakpoints.up('md')]: {
        h3: {
            fontSize: "1.3rem"
        },
        h6: {
            fontSize: "1.3rem",
        }
    },
    [theme.breakpoints.up('lg')]: {
        h3: {
            fontSize: "2.3rem"
        },
    },
    [theme.breakpoints.up('xl')]: {
        h3: {
            fontSize: "2.7rem"
        },
    }
}));

const StackContainer = ({children}) => {
    return (
        <StackItem spacing={3}>
            {children}
        </StackItem>
    )
}

export default StackContainer;
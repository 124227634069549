import {Grid, styled, Typography, Button} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchData, getPropertyValue} from "../../utils";
import {API_URL} from "../../config";
import {NavLink} from "react-router-dom";
import {MUSEUM_DESCRIPTION_PROPERTY_UUID} from "../../enums";

const MuseumTitleItem = styled(Typography)(({theme}) => ({
    color: theme.palette.common.grey,
    padding: theme.spacing(0.5),
}))

const MuseumDescription = styled(Typography)(() => ({
    textAlign: "justify",
}))

const MuseumDescriptionItem = ({item}) => {

    const [museumData, setMuseumData] = useState({})

    useEffect(() => {
        if (item?.uuid) {
            fetchData(`${API_URL}/public/containers/${item.uuid}`).then(response => {
                setMuseumData(response)
            })
        }
    }, [item?.uuid]);

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <MuseumTitleItem variant="h2">
                    {museumData?.label}
                </MuseumTitleItem>
            </Grid>
            <Grid item xs={12}>
                <MuseumDescription variant="body2">
                    {getPropertyValue(MUSEUM_DESCRIPTION_PROPERTY_UUID, museumData)}
                </MuseumDescription>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" spacing={12}>
                    <Grid item xs={12}>
                        <Button sx={{textTransform:'capitalize'}} variant="contained" component={NavLink} to={museumData?.uuid}>
                            Περισσότερα
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default MuseumDescriptionItem

import {Grid, styled} from "@mui/material";

const CustomGridContainer = styled(Grid)(({theme}) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(4),
}))

const GridContainer = ({children}) => {
    return (
        <CustomGridContainer>
            {children}
        </CustomGridContainer>
    )
}
export default GridContainer;

import StackContainer from "../../components/@core/StackContainer";
import GridContainer from "../../components/@core/GridContainer";
import {Suspense, useEffect, useState} from "react";
import {fetchData} from "../../utils";
import {API_URL} from "../../config";
import Carousel from "react-multi-carousel";
import {Grid, styled, Typography} from "@mui/material";
import StoriesVideoCards from "../../components/Stories/StoriesVideoCards";
import {STORIES_UUID} from "../../enums";


const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 1,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 1,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  }
};

const LabelHeader = styled(Typography)(({theme}) => ({
  color: theme.palette.common.grey,
  textAlign: 'center'
}))

const Stories = () => {

  const [storiesItems, setStoriesItems] = useState([])
  const [currentStory, setCurrentStory] = useState(0)

  useEffect(() => {
    fetchData(`${API_URL}/public/containers/${STORIES_UUID}`).then(async (response) => {
      setStoriesItems(response.childContainers)
    })
  }, [])

  if (storiesItems?.length > 0)
    return (
      <StackContainer>
        <GridContainer>
          <Grid item xs={12}>
            {storiesItems.length > 0 &&
              <LabelHeader variant="h2" key={`title-${currentStory}`}>
                {storiesItems[currentStory].label}
              </LabelHeader>
            }
          </Grid>
          <Carousel
            swipeable={true}
            draggable={true}
            key={'main-carousel'}
            arrows={true}
            responsive={responsive}
            beforeChange={(slideNumber) => {
              setCurrentStory(slideNumber)
            }}
            rewindWithAnimation
            focusOnSelect={true}
          >
            {storiesItems?.map((storyCover) => {
              return (
                <div
                  key={storyCover.uuid}
                  style={{
                    width: '100%',
                    height: '55vh',
                    position: "relative",
                    textAlign: 'center',
                    color: 'white',
                  }}
                >
                  <img
                    alt={storyCover.label}
                    src={storyCover?.coverFile?.viewUrl}
                    style={{objectFit: 'cover', width: '80%', height: '100%'}}
                  />
                </div>
              )
            })}
          </Carousel>
          <Grid item xs={12} style={{height:300}}>
            {storiesItems?.length > 0 && <StoriesVideoCards uuid={storiesItems[currentStory].uuid} key={`videos-${currentStory}`}/>}
          </Grid>
        </GridContainer>
      </StackContainer>
    )

  return <Suspense>
    Loading
  </Suspense>
}

export default Stories;

import {useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";

import {
    useTheme,
    Box,
    CssBaseline,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Drawer, styled,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {navigationPages} from "../../../constants/Enums";
import MuiAppBar from "@mui/material/AppBar";
import bgImage from "../../../assets/Images/Header/background-header.jpg";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down('md')]: {
        backgroundPosition: "center"
    },
    [theme.breakpoints.down('sm')]: {
        backgroundPosition: "right top"
    },

}));

const CustomDrawer = styled('div')(({theme}) => ({
    display: 'flex',
    flex: 0,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function Index() {

    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box onClick={toggleDrawer}>
            <CssBaseline/>
            <AppBar position="static" open={open} color="transparent">
                <Toolbar sx={{backgroundColor: "#fff", opacity: 0.8}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        edge="start"
                        sx={{...(open && {display: 'none'})}}

                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" sx={{fontWeight: 600}}>
                        Menu
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <CustomDrawer onClick={toggleDrawer}>

                    <NavLink to="/" style={{textDecoration: "none", color: "black"}}>
                        <span style={{marginRight: "5px"}}>
                            <img src="https://acropolis-diaspora.repox.io/favicon.ico"
                                 alt="acropolis icon logo"></img>
                        </span>

                        <Typography variant="span" sx={{fontWeight: 700,}}>
                            Μουσείο Ακρόπολης
                        </Typography>
                    </NavLink>

                    <IconButton>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </CustomDrawer>
                <Divider/>
                <List>
                    {navigationPages.map((page) => {
                        return (
                            <ListItem key={page.label} disablePadding>
                                <ListItemButton onClick={() => {
                                    navigate(page.path);
                                    toggleDrawer();
                                }

                                }>
                                    {<ListItemIcon>
                                        {page.icon}
                                    </ListItemIcon>}
                                    <ListItemText primary={page.label}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>
            </Drawer>
        </Box>
    );
}

export default Index

import React from 'react';

function getVideoEmbedUrl(url) {
  let videoId;
  let embedUrl;

  if (url.includes('youtube.com') || url.includes('youtu.be')) {

    const youtubeMatch = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    videoId = youtubeMatch ? youtubeMatch[1] : null;
    embedUrl = videoId ? `https://www.youtube.com/embed/${videoId}` : null;
  } else if (url.includes('vimeo.com')) {
    // Extract Vimeo video ID
    const vimeoMatch = url.match(/vimeo\.com\/(?:video\/)?([0-9]+)/);
    videoId = vimeoMatch ? vimeoMatch[1] : null;
    embedUrl = videoId ? `https://player.vimeo.com/video/${videoId}` : null;
  }

  return embedUrl;
}

const VideoEmbeddedPlayer = ({ url }) => {
  const embedUrl = getVideoEmbedUrl(url);

  if (!embedUrl) {
    return <div>Invalid video URL</div>;
  }

  return (
    <iframe
      style={{ width: '100%', height: '100vh', border: 'none',backgroundColor:'black'}}
      src={embedUrl}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
};

export default VideoEmbeddedPlayer;

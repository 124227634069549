import {Routes, Route} from "react-router";
import {BrowserRouter} from "react-router-dom";

import {CssBaseline, ThemeProvider} from "@mui/material";

import Homepage from "./pages/Homepage";
import QuickLook from "./pages/QuickLook";
import Stories from "./pages/Stories";
import SearchAssets from "./pages/SearchAssets";
import Timeline from "./pages/Timeline";
import Museums from "./pages/Museums";
import customTheme from "./assets/Styles/theme.js";
import Video from "./components/IntroVideo/Video";
import {useSessionStorage} from "./hooks/useSessionStorage";
import DefaultLayout from "./components/Layouts/DefaultLayout";
import MuseumId from "./pages/Museums/MuseumId";
import ExhibitId from "./pages/Museums/ExhibitId";
import SearchAssetId from "./pages/SearchAssets/SearchAssetId";

function App() {

    const [isPlaying, setIsPlaying] = useSessionStorage(false, 'isPlaying');

    return (
        <>
            {!isPlaying && <Video setIsPlaying={setIsPlaying}/>}

            <BrowserRouter>
                <ThemeProvider theme={customTheme}>
                    <DefaultLayout>
                        <CssBaseline/>
                        <Routes>
                            <Route path="/" element={<Homepage/>}/>
                            <Route path="/exhibits/:uuid" element={<ExhibitId/>}/>
                            <Route path="/me-mia-matia" element={<QuickLook/>}/>
                            <Route path="/museums" element={<Museums/>}/>
                            <Route path="/museums/:uuid" element={<MuseumId/>}/>
                            <Route path="/stories" element={<Stories/>}/>
                            <Route path="/evretirio" element={<SearchAssets/>}/>
                            <Route path="/evretirio/:uuid" element={<SearchAssetId/>}/>
                            <Route path="/xronologio" element={<Timeline/>}/>
                        </Routes>
                    </DefaultLayout>
                </ThemeProvider>
            </BrowserRouter>

        </>
    )
}

export default App

import {Button, Divider, Grid, Paper, Stack, styled, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {fetchData, getPropertyMultipleValues, getPropertyValue} from "../../utils";
import {API_URL} from "../../config";
import {
  EXHIBIT_URL1_PROPERTY_UUID, EXHIBIT_URL2_PROPERTY_UUID,
} from "../../enums";
import ClipLoader from "react-spinners/ClipLoader";
import FlipImage from "../@core/FlipImage";
import LinkIcon from '@mui/icons-material/Link';
import {Link} from "react-router-dom";

const ExhibitPaper = styled(Paper)(() => ({
  width: "100%",
  borderRadius: "10px",
  minHeight: 500,
  height: 'auto'
}));

const ExhibitHeader = styled(Typography)(({theme}) => ({
  textAlign: "center",
  color: "#9D9D9D",
}))

const ExhibitCard = ({uuid,openExhibit=false}) => {

  const [exhibit, setExhibit] = useState({})
  const [loading,setLoading] = useState(false)

  useEffect(() => {
    if (uuid) {
      setLoading(true)
      fetchData(`${API_URL}/public/containers/${uuid}`).then((exhibitResponse) => {
        setExhibit(exhibitResponse)
        setLoading(false)
      }).catch((e)=>{setLoading(false)})
    }
  }, [uuid]);

  if (!exhibit?.uuid)
    return <ExhibitPaper>
      <ClipLoader loading={loading} color={'#6262de'} size={40}/>
    </ExhibitPaper>

  return (
    <ExhibitPaper variant="outlined" sx={{p: 2,position:'relative'}}>
      <Grid container direction="column">
        <Grid item>
          <FlipImage front={exhibit?.datastreams[0].bitstreamFile?.viewUrl}/>
        </Grid>
        <Grid item xs>
          <ExhibitHeader variant="h5">
            {exhibit?.label}
          </ExhibitHeader>
        </Grid>
        <Grid item mt={5}>
        {(getPropertyMultipleValues(EXHIBIT_URL1_PROPERTY_UUID, exhibit)?.length>0) &&
          <Stack direction={'column'} spacing={1}>
            <Typography variant={'caption'}>Σύνδεσμοι</Typography>
            <Divider sx={{width:'100%'}}/>
            {getPropertyMultipleValues(EXHIBIT_URL1_PROPERTY_UUID, exhibit).map((propertyValue,idx)=>{
                return <Stack direction={'row'} spacing={1} alignItems={'center'} key={`url-${propertyValue}-${idx}`}>
                  <LinkIcon sx={{fontSize:18,color:'#22409a'}}/>
                  <Link aria-label={propertyValue} style={{fontSize:12,color:'#22409A'}} to={`${getPropertyValue(EXHIBIT_URL1_PROPERTY_UUID, exhibit)}`} target={'_blank'} rel={'nofollow'}>
                    {`Εξωτερικός σύνδεσμος - ${idx+1}`}
                  </Link>
                </Stack>
              })
            }
          </Stack>
        }
        </Grid>
        {openExhibit && <Grid item pt={8}>
          <Button href={`/evretirio/${uuid}`} variant={'outlined'} sx={{textTransform: 'capitalize',position:'absolute',bottom:20,width:'90%'}}>Περισσότερα</Button>
        </Grid>}
      </Grid>
    </ExhibitPaper>
  )
}

export default ExhibitCard
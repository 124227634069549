import {NavLink} from "react-router-dom";
import {Button, Grid, styled} from "@mui/material";
import {navigationPages} from "../../../constants/Enums";

const NavigationButton = styled(Button)(({theme}) => ({
    textTransform: "Capitalize",
    fontSize: "1.1rem",
    fontWeight: "550",
    color: theme.palette.common.white,
    '&:hover': {
        fontWeight: "700",
    }

}));

function Index() {
    return (

        <Grid container>
            <Grid item xs={12}>
                {navigationPages.map((page) => {
                    return (
                        <NavigationButton
                            key={page.label}
                            component={NavLink}
                            to={page.path}
                            disableRipple={true}
                        >
                            {page.label}
                        </NavigationButton>
                    );
                })}
            </Grid>
        </Grid>

    )
}

export default Index

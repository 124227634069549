import {Typography} from "@mui/material";
import StackContainer from "../../components/@core/StackContainer";

const Timeline = () => {
    return (
        <StackContainer>
            <Typography variant="h3">
                Χρονολόγιο
            </Typography>
        </StackContainer>
    )
}

export default Timeline;

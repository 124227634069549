import {Stack} from "@mui/material";
import LogoContainer from "../../Header/common/LogoContainer";
import MenuContainer from "../../Header/common/MenuContainer";

const Header = () => {

    return (
        <header>
            <Stack>
                <LogoContainer/>
                <MenuContainer/>
            </Stack>
        </header>
    );
}

export default Header


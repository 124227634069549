import {Container} from "@mui/material";
import Header from "./Header";
import Main from "./Main";

const DefaultLayout = ({children}) => {
    return (
        <Container maxWidth={false} disableGutters>
            <Header top/>
            <Main children={children}/>
        </Container>
    )
}

export default DefaultLayout;

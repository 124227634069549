


//UUIDs
export const HOME_UUID = '780d2a89-bcd3-43db-91f1-0e3c9c31e156'
export const MUSEUMS_UUID = 'c6a794fc-08c9-43e5-b9a8-614a4abe8ea3'
export const ME_MIA_MATIA_UUID = '6a221487-ae84-443a-9fa6-f25af09831ea'
export const STORIES_UUID = '27cee47f-d647-43e6-86be-b73cd9953102'

// Container Types
export const MEDIA_CONTAINER_TYPE_SLUG = 'ct:media'
export const EXHIBIT_CONTAINER_TYPE_SLUG = 'ct:exhibits-(collection)'
export const EXHIBIT_ITEM_TYPE_SLUG = 'ct:exhibit'
export const EXHIBIT_CONTAINER_TYPE_UUID = '78dee3f1-90f3-4fca-b848-88901ff675b9'
export const EXHIBIT_COLLECTIONS_CONTAINER_TYPE_UUID = 'c933bc2a-7cee-4ec6-80b1-df6d6e322148'

// Property UUIDs
export const MUSEUM_DESCRIPTION_PROPERTY_UUID = '0cdb8726-592c-45ec-b77b-68e3e5858e21'
export const MUSEUM_WEBSITE_PROPERTY_UUID = '709f6805-bb68-49cd-b05d-ec90d58b6d35'
export const EXHIBIT_DESCRIPTION_PROPERTY_UUID = '7ccdfbbd-871d-465f-b796-c3b970b4da64'
export const EXHIBIT_URL1_PROPERTY_UUID = '8ab54348-e450-4c51-8b03-71ac4aea782c'
export const EXHIBIT_URL2_PROPERTY_UUID = ''
export const STORIES_EMBEDDED_VIDEO_SCHEMA_UUID = '345d2fb1-28d5-4e89-bef2-4e5fd7f8acca'


// Datastream Types
export const MEDIA_COVER_DATASTREAM_TYPE = 'dt:cover'
export const CAROUSEL_DATASTREAM_TYPE = 'dt:carousel'
export const ME_MIA_MATIA_YPERSUNOLO_TYPE ='dt:eikona-ypersynoloy'
export const ME_MIA_MATIA_LEGEND_TYPE = 'dt:lezanta-ypersynoloy'


// Search Property Types UUIDs
export const SEARCH_ERA = '9bfb5f8a-8493-4699-8c02-26b06607945f'
export const SEARCH_TYPE = 'bb81ae68-71cd-4b80-a966-027c82b19630'
export const SEARCH_MONUMENT = 'ffb8cdec-391c-4df9-88ed-050316c71fac'
export const SEARCH_MUSEUM = 'c42b77bf-c0bf-4807-8ce0-4b16a0bd1c40'


export const searchTypeSchemaProperties = [
  {value:'agalma',label:'Άγαλμα'},
  {value:'aggeio',label:'Αγγείο'},
  {value:'arxitektoniko-glypto',label:'Αρχιτεκτονικό γλυπτό'},
  {value:'arxitektoniko-melos',label:'Αρχιτεκτονικό μέλος'},
  {value:'epigrafh',label:'Επιγραφή'},
]

export const eraSchemaProperties = [
  {value:'arxaikh-epoxh',label:'Αρχαϊκή εποχή'},
  {value:'klasikh-epoxh',label:'Κλασική εποχή'},
  {value:'rwmaikh-epoxh',label:'Ρωμαϊκή εποχή'},
]

export const searchMonumentSchemaProperties = [
  {value:'erex8eio-karyatides',label:'Ερέχθειο, Καρυάτιδες'},
  {value:'naos-a8hnas-nikhs-anatolikh-zwoforos',label:'Ναός Αθηνάς Νίκης, ανατολική ζωφόρος'},
  {value:'naos-a8hnas-nikhs-boreia-zwoforos',label:'Ναός Αθηνάς Νίκης, βόρεια ζωφόρος'},
  {value:'naos-a8hnas-nikhs-dytikh-zwoforos',label:'Ναός Αθηνάς Νίκης, δυτική ζωφόρος'},
  {value:'naos-a8hnas-nikhs-notia-zwoforos',label:'Ναός Αθηνάς Νίκης, νότια ζωφόρος'},
  {value:'par8enwnas',label:'Παρθενώνας'},
  {value:'par8enwnas-noties-metopes',label:'Παρθενώνας, νότιες μετόπες'},
  {value:'par8enwnas-anatolikh-zwoforos',label:'Παρθενώνας, ανατολική ζωφόρος'},
  {value:'par8enwnas-boreia-zwoforos',label:'Παρθενώνας, βόρεια ζωφόρος'},
  {value:'par8enwnas-dytiko-aetwma',label:'Παρθενώνας, δυτικό αέτωμα'},
]

export const searchMuseumSchemaProperties = [
  {value:'akropolh-a8hna' ,label:"Ακρόπολη, Αθήνα"},
  {value:'moyseio-akropolhs-a8hna' ,label:"Μουσείο Ακρόπολης, Αθήνα"},
  {value:'bretaniko-moyseio-londino' ,label:"Βρετανικό Μουσείο, Λονδίνο"},
  {value:'e8niko-moyseio-ths-danias-kopegxagh' ,label:"Εθνικό Μουσείο της Δανίας, Κοπεγχάγη"},
  {value:'moyseio-kalwn-texnwn-lywn' ,label:"Μουσείο Καλών τεχνών, Λυών"},
  {value:'moyseio-ronten-parisi' ,label:"Μουσείο Rodin, Παρίσι"},
]

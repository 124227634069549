import {Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchData} from "../../utils";
import {API_URL} from "../../config";
import QuickLookCard from "../../components/QuickLookCard";
import {ME_MIA_MATIA_UUID} from "../../enums";

const QuickLook = () => {

    const [quickLookItems,setQuickLookItems] = useState([])

    useEffect(()=>{
        fetchData(`${API_URL}/public/containers/${ME_MIA_MATIA_UUID}`).then((response) => {
            setQuickLookItems(response.childContainers)
        })
    },[])

    return (

        <Stack direction={'column'} spacing={10} mt={5} justifyContent={'center'} alignItems={'center'}>
            {quickLookItems.map((item)=>{
                return <QuickLookCard key={item.uuid} item={item}/>
            })}
        </Stack>

    )
}

export default QuickLook;
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import VideoEmbeddedPlayer from "../VideoEmbeddedPlayer";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const VideoDialog = ({selectedVideo,handleClose}) =>{


    return (
            <Dialog
                fullScreen
                open={selectedVideo}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative',bgcolor:'#131313',color:'#fff'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            {selectedVideo?.label}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {selectedVideo?.isEmbedded ?
                   <VideoEmbeddedPlayer url={selectedVideo.url}/>
                  :
                  <video src={selectedVideo?.url} controls autoPlay loop
                         style={{height: 'calc(100vh - 64px)', background: '#131313'}}/>
                }
            </Dialog>
    );
}

export default VideoDialog

import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {fetchData, getPropertyValue} from "../../utils";
import {
    API_URL,
} from "../../config";
import {Breadcrumbs, Divider, Grid, styled, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import ImagesCarousel from "../../components/ImagesCarousel";
import StackContainer from "../../components/@core/StackContainer";
import ExhibitItem from "../../components/Exhibits/ExhibitItem";
import {
    EXHIBIT_CONTAINER_TYPE_SLUG,
    MEDIA_CONTAINER_TYPE_SLUG,
    MUSEUM_DESCRIPTION_PROPERTY_UUID,
    MUSEUM_WEBSITE_PROPERTY_UUID
} from "../../enums";

const BreadcrumbItem = styled(Typography)(({theme}) => ({
    textTransform: "Capitalize",
    fontWeight: "600",
    color: theme.palette.common.black,
}));

const MuseumId = () => {

    const {uuid} = useParams();

    const [currentMuseum, setCurrentMuseum] = useState([]);
    const [, setMuseumUrl] = useState("");
    const [carouselImages, setCarouselImages] = useState([])
    const [exhibits, setExhibits] = useState([])

    useEffect(() => {
        if (uuid) {
            fetchData(`${API_URL}/public/containers/${uuid}`).then(response => {
                setCurrentMuseum(response);

                const url = response.properties.find((child) => child.propertyUuid === MUSEUM_WEBSITE_PROPERTY_UUID)
                setMuseumUrl(url?.value);

                const exhibits = response.childContainers.find((childContainer) => childContainer.type.slug === EXHIBIT_CONTAINER_TYPE_SLUG)
                const media = response.childContainers.find((childContainer) => childContainer.type.slug === MEDIA_CONTAINER_TYPE_SLUG)
                if (exhibits?.uuid)
                    fetchData(`${API_URL}/public/containers/${exhibits.uuid}`).then((exhibitsResponse) => {
                        setExhibits(exhibitsResponse.childContainers)
                    })
                if (media?.uuid)
                    fetchData(`${API_URL}/public/containers/${media.uuid}`).then((exhibitsResponse) => {
                        setCarouselImages(exhibitsResponse.datastreams)
                    })

            })
        }
    }, [uuid])

    return (
        <StackContainer>
            <Grid container spacing={2} sx={{alignItems: "center"}}>
                <Grid item>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography component={Link} to="/museums" variant="caption"
                                    sx={{textTransform: "Capitalize", textDecoration: "none", color: "#000"}}>
                            Μουσεία / Χώροι
                        </Typography>
                        <BreadcrumbItem variant="caption">
                            {currentMuseum?.label}
                        </BreadcrumbItem>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Grid container spacing={3}
                  sx={{justifyContent: "center", alignContent: "center"}}>
                <Grid item>
                    <Typography variant="h2" color="#9D9D9D">
                        {currentMuseum?.label}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ImagesCarousel carouselImages={carouselImages}/>
                </Grid>
                <Grid item xs={10}>
                            <Typography variant="body1" sx={{textAlign: "justify"}}>
                                {getPropertyValue(MUSEUM_DESCRIPTION_PROPERTY_UUID, currentMuseum)}
                            </Typography>
                </Grid>
                {exhibits?.length > 0 && (
                    <>
                        <Grid item xs={10}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container direction={'column'}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" color="#9D9D9D">
                                        Εκθέματα
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <Grid container spacing={2} justifyContent={'center'}>
                                        {exhibits?.map((exhibit) => {
                                            return <Grid item key={exhibit.uuid} xs={4}>
                                                <ExhibitItem uuid={exhibit.uuid}/>
                                            </Grid>
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </StackContainer>
    )
}
export default MuseumId

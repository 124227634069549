import {Grid, Paper, styled,} from "@mui/material";
import MuseumDescriptionItem from "./MuseumDescriptionItem";

const MuseumPaper = styled(Paper)(({theme}) => ({
    width: "100%",
    borderRadius: "10px",
    border: "none",
    boxShadow: '1px 2px 5px 0px #c8c8c8',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
}));

const MuseumImageItem = styled("img")(() => ({
    width: "100%",
    objectFit: "cover",
    height: 400,
    borderRadius: "15px",
}));

const MuseumGridImageItem = styled(Grid)(({theme}) => ({
    padding: theme.spacing(2),
    display: "flex",
}))

const MuseumGridDescriptionItem = styled(Grid)(({theme}) => ({
    padding: theme.spacing(4),
}))

const MuseumItem = ({item, index}) => {

    return (
        <MuseumPaper elevation={4}>
            <Grid container direction={{xs: "column", lg: index % 2 === 0 ? "row" : "row-reverse"}}>
                <MuseumGridImageItem item xs={6}>
                    <MuseumImageItem src={item?.coverFile?.viewUrl}/>
                </MuseumGridImageItem>
                <MuseumGridDescriptionItem item xs={6}>
                    <MuseumDescriptionItem item={item}/>
                </MuseumGridDescriptionItem>
            </Grid>
        </MuseumPaper>
    )
}
export default MuseumItem

import {Box, Button, Divider, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchData} from "../../utils";
import {API_URL} from "../../config";
import ImagesCarousel from "../../components/ImagesCarousel";
import {HOME_UUID} from "../../enums";
import SearchIcon from "@mui/icons-material/Search";
import {AccountBalance, Book, FindInPage} from "@mui/icons-material";
import theme from "../../assets/Styles/theme";

const showCaseOptions = [
    {id:1,title :'Με μια ματιά',link:'/me-mia-matia',icon :SearchIcon,description:'Το Μουσείο Ακρόπολης επιλέγει μια καινοτόμο προσέγγιση για την ανάδειξη του θέματος της διασποράς και των περιπετειών των γλυπτών και των αρχιτεκτονικών μελών των μνημείων της Ακρόπολης. Μέσα από τη χρωματική διαφοροποίηση επικοινωνείται εύγλωττα ο διαμελισμός και η διασπορά τους. Σχέδια του 1674, αλλά και πιο σύγχρονα, όπου υπάρχουν, αποδίδουν τα χαμένα κομμάτια.'},
    {id:2,title :'Μουσεία',link:'/museums',icon :AccountBalance,description:'Στο πεδίο Μουσεία, θα δείτε σε ποια μουσεία βρίσκονται αντικείμενα που προέρχονται από την Ακρόπολη και ποια είναι αυτά τα αντικείμενα.'},
    {id:3,title :'Ιστορίες',link:'/stories',icon :Book,description:'Για την περιήγησή σας μπορείτε να ανατρέξετε στο ιστορικό χρονολόγιο όπου ξετυλίγονται τα γεγονότα που προκάλεσαν τις περιπέτειες.'},
    {id:4,title :'Ευρετήριο',link:'/evretirio',icon :FindInPage,description:'Με το ευρετήριο μπορείτε να εξερευνήσετε, με βάση διαφορετικών φίλτρων, τα μνημεία, τα γλυπτά της Ακρόπολης.'},
]

const Homepage = () => {
    const [homeImages,setHomeImages] = useState([])
    useEffect(()=>{
        fetchData(`${API_URL}/public/containers/${HOME_UUID}`).then(response => {
            setHomeImages(response?.datastreams)
        })
    },[])

    return (
        <Stack direction={'column'} spacing={1} mt={2} pb={10}>

            <ImagesCarousel carouselImages={homeImages} carouselHeight={'40dvh'}/>

            <Typography variant="body1" style={{fontSize:18}}>
                Μέσω αυτής της ψηφιακής εφαρμογής, θα ανακαλύψετε τις περιπέτειες των
                μνημείων της Ακρόπολης που είχαν ως αποτέλεσμα τον διαμελισμό και τη
                διασπορά σπουδαίων έργων τέχνης, από τα οποία τα πιο γνωστά είναι τα
                γλυπτά του Παρθενώνα. Η εφαρμογή αυτή βρίσκεται σε εξέλιξη και θα
                εμπλουτίσει συνεχώς. Σκοπός είναι να δοθεί στο κοινό τα στοιχεία της
                μακρύς και πολυτάραχης ιστορίας της Ακρόπολης και τις συνέπειες τους
                όσο στα μνημεία τόσο τα έργα τέχνης του Ιερού Βράχου. Άλλος στόχος
                είναι να δοθεί μια πλήρη εικόνα μερικών αντικειμένων, τμήματα των ποιων
                βρίσκονται διαμελισμένα μεταξύ διαφορετικών μουσείων. Σκοπός επίσης
                είναι να δοθεί πλήρη εκτίμηση των συλλόγων του μουσείου Ακρόπολης,
                συμπεριλαμβάνοντας τα έργα της Ακρόπολης που βρίσκονται σήμερα σε αλλα
                μουσεία.
            </Typography>
            <Divider sx={{width:'100%'}}/>

            <Stack direction ='row' spacing={1}>
                {showCaseOptions.map((option)=>{
                    const OptionIcon = option.icon
                    return <Stack direction={'column'} alignItems={'center'} spacing={3} key={option.id} style={{minHeight:300,height:'auto',border:'1px solid #ececec',borderRadius:10,padding:30,width:300,position:'relative'}}>
                        <Typography variant={'h5'}>{option.title}</Typography>
                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'row'} style={{width:50,height:50,borderRadius:25,backgroundColor:theme.palette.secondary.light}}><OptionIcon sx={{fontSize: 38,color:'white'}}/></Box>
                       <Typography textAlign={'center'} pb={9} variant={'body2'}>{option.description}</Typography>
                        <Box style={{position:'absolute',bottom:10,paddingBottom:10}}>
                            <Button size={'large'} sx={{textTransform:'capitalize'}} fullWidth color={'secondary'} href={option.link} variant={'contained'}>Περισσότερα</Button>
                        </Box>
                    </Stack>
                })}
            </Stack>

        </Stack>
    );
}

export default Homepage;


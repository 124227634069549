import {Box} from "@mui/material";

const FlipImage = ({front, back, flipped, setFlipped}) => {

  return <Box flex mt={3} mb={1} onClick={() => back && setFlipped(!flipped)} style={{
    perspective: '1000px',
    cursor: back ? 'pointer' : ''
  }}>
    <Box flex style={{
      transform: flipped ? 'rotateY(180deg)' : 'rotateY(0)',
      transition: 'transform 0.8s',
      transformStyle: 'preserve-3d'
    }}>
      <Box display={'flex'} style={{backfaceVisibility: flipped ? '' : ''}} alignItems={'center'}
           justifyContent={'center'}>
        {!flipped ? <img src={front} height={200}
                         alt={`front`}
                         style={{objectFit: 'cover', transform: flipped ? 'rotateY(180deg)' : 'rotateY(0)',}}/>
          : <img src={back} height={200}
                 alt={'back'}
                 style={{objectFit: 'cover', transform: flipped ? 'rotateY(180deg)' : 'rotateY(0)',}}/>}
      </Box>
    </Box>
  </Box>
}


export default FlipImage
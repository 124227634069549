import {Grid, Paper, styled, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchData, getPropertyValue} from "../../utils";
import {API_URL} from "../../config";
import FlipImage from "../@core/FlipImage";
import {EXHIBIT_DESCRIPTION_PROPERTY_UUID} from "../../enums";


const ExhibitPaper = styled(Paper)(() => ({
    width: "100%",
    borderRadius: "10px",
    minHeight: 500,
    height: 'auto'
}));

const ExhibitHeader = styled(Typography)(({theme}) => ({
    color: "#2f2f2f",
}))

const GridExhibitImageItem = styled(Grid)(({theme}) => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
}))

const ExhibitItem = ({uuid}) => {

    const [exhibit, setExhibit] = useState({})
    const [flipped, setFlipped] = useState(false)

    useEffect(() => {
        if (uuid)
            fetchData(`${API_URL}/public/containers/${uuid}`).then((exhibitResponse) => {
                setExhibit(exhibitResponse)
            })
    }, [uuid]);

    if (!exhibit?.uuid)
        return <ExhibitPaper>
            loading..
        </ExhibitPaper>

    return (
        <ExhibitPaper variant="outlined" sx={{p:2}}>
            <Grid container direction="column">
                <Grid item xs>
                    <FlipImage front={exhibit?.datastreams[0].bitstreamFile?.viewUrl} back={exhibit?.datastreams[1].bitstreamFile?.viewUrl} flipped={flipped} setFlipped={setFlipped}/>
                </Grid>
                <Grid item xs>
                    <ExhibitHeader variant="h5">
                        {exhibit?.label}
                    </ExhibitHeader>
                </Grid>
                <GridExhibitImageItem item xs>
                    <ExhibitHeader variant={'body1'}>
                        {getPropertyValue(EXHIBIT_DESCRIPTION_PROPERTY_UUID, exhibit)}
                    </ExhibitHeader>
                </GridExhibitImageItem>
            </Grid>
        </ExhibitPaper>
    )
}

export default ExhibitItem
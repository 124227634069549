import {createTheme} from "@mui/material";

const customTheme = createTheme({
    // Customize default components
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: "2rem",
                }
            }

        },
        Drawer: {
            styleOverrides: {
                root: {
                    margin: 0,
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {}
            }
        }
    },

    // Default Palette Colors based of acropolis Logo
    palette: {
        primary: {
            main: "#2A2A7F",
        },
        secondary: {
            main: "#bd7b72",
            light: "#dca096",
        },
        common: {
            black: "#000",
            white: "#FFF",
            grey: "#9D9D9D",
        },
    },

    typography: {
        fontFamily: "'Fira Sans Condensed', sans-serif",
        textAlign: "center",
        h2: {
            fontWeight: 900,
            fontSize: "3rem",
            lineHeight: 1.6,
        },
        h3: {},
        h6: {
            textAlign: "justify"
        },
    },

})

export default createTheme(customTheme);
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import {Stack, Typography} from "@mui/material";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
    }
};

const ImagesCarousel = ({carouselImages,carouselHeight='55dvh'}) => {

    if (carouselImages?.length > 0)
        return (
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={carouselImages?.length > 1}
                responsive={responsive}
                rewindWithAnimation
                focusOnSelect={true}
            >

                {carouselImages.map((datastream) => {
                    return (
                      <Stack key={datastream.uuid}>
                        <div
                            style={{
                                width: '100%',
                                height: carouselHeight,
                                position: "relative",
                                textAlign: 'center',
                                color: 'white',
                            }}
                        >
                            <img
                                alt={datastream.label}
                                src={datastream?.bitstreamFile?.viewUrl}
                                style={{objectFit: 'contain', width: '80%', height: '100%'}}
                            />
                        </div>
                            <Typography align={'center'} style={{marginBottom:30,marginTop:10}}>{datastream.label}</Typography>
                      </Stack>
                    )
                })}
            </Carousel>
        )
}

export default ImagesCarousel

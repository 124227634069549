import {useEffect, useState} from "react";
import {fetchData, getPropertyValue} from "../../utils";
import {API_URL} from "../../config";
import {useParams} from "react-router";
import {Breadcrumbs, Grid, Stack, Typography} from "@mui/material";
import QuickLookCard from "../../components/QuickLookCard";
import {Link} from "react-router-dom";
import {EXHIBIT_DESCRIPTION_PROPERTY_UUID} from "../../enums";

const SearchAssetId = () => {
  const [loading, setLoading] = useState(false)
  const [exhibit, setExhibit] = useState(null)
  const {uuid} = useParams()

  useEffect(() => {
    if (uuid) {
      setLoading(true)
      fetchData(`${API_URL}/public/containers/${uuid}`).then((exhibitResponse) => {
        setExhibit(exhibitResponse)
        setLoading(false)
      }).catch((e) => {
        setLoading(false)
      })
    }
  }, [uuid]);
  return <Stack direction={'column'} mt={5} pb={10}>
    <Breadcrumbs aria-label="breadcrumb">
      <Typography component={Link} to="/evretirio" variant="caption"
                  sx={{textTransform: "Capitalize", textDecoration: "none", color: "#000"}}>
        Ευρετήριο
      </Typography>
      <Typography variant="caption" fontWeight={'bold'}>
        {exhibit?.label}
      </Typography>
    </Breadcrumbs>
    <Grid container mt={2} spacing={2} direction={'row'} alignItems={'center'}>
      <Grid item xs={6}>
        <Stack direction={'column'}>
          <Typography variant="h3">{exhibit?.label}</Typography>
          <Typography variant="body1">
            {getPropertyValue(EXHIBIT_DESCRIPTION_PROPERTY_UUID, exhibit)}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          {exhibit?.datastreams?.map((datastream) => {
            return <img src={datastream.bitstreamFile.viewUrl} height={200} key={datastream.uuid}
                        alt={datastream.label}/>
          })}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction={'column'} spacing={2} mt={4}>
          {exhibit?.childContainers.map((item) => {
            return <QuickLookCard key={item.uuid} item={item} hideTitle={true} maxWidth={'100%'}/>
          })}
        </Stack>
      </Grid>
    </Grid>
  </Stack>
}

export default SearchAssetId
import {Grid, Pagination, Typography,} from "@mui/material";
import FormIndex from "../../components/Form/FormIndex";
import StackContainer from "../../components/@core/StackContainer";
import {useEffect, useState} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import ExhibitCard from "../../components/Cards/ExhibitCard";

const SearchAssets = () => {
  const [searchResults,setSearchResults] = useState(null)
  const [loading,setLoading] = useState(false)

    return (
        <StackContainer mt={5}>

            <FormIndex setSearchResults={setSearchResults} loading={loading} setLoading={setLoading}/>
            <Grid container alignItems={'center'} direction={'column'}>
              <Grid item>
              <ClipLoader loading={loading} color={'#6262de'} size={150}/>
              </Grid>
              {searchResults?.content?.length === 0 && !loading &&
                <Typography variant="h4" sx={{textAlign: "center"}}>
                    Δεν υπάρχουν αποτελέσματα
                </Typography>
              }
              {searchResults?.content?.length > 0 && !loading &&
                <Grid container spacing={1}>
                  {searchResults?.content?.map((exhibit)=>{
                    return <Grid item xs={4} key={exhibit.uuid}>
                          <ExhibitCard uuid={exhibit.uuid} openExhibit={true}/>
                    </Grid>
                  })}
                </Grid>
              }
            </Grid>
        </StackContainer>
    )

        ;
}

export default SearchAssets;

import {useLocation, useParams} from "react-router";
import {useEffect, useState} from "react";
import {fetchData} from "../../utils";
import {API_URL} from "../../config";
import {
    Breadcrumbs,
    Grid,
    Typography,
    styled,
    Card,
    CardActionArea,
    CardContent, CardActions
} from "@mui/material";
import {Link} from "react-router-dom";
import StackContainer from "../../components/@core/StackContainer";

const BreadcrumbItem = styled(Typography)(({theme}) => ({
    textTransform: "Capitalize",
    fontWeight: "600",
    color: theme.palette.common.black,
}));

const ExhibitId = () => {

    const {state} = useLocation();
    const {uuid} = useParams();

    const [exhibits, setExhibits] = useState([])

    useEffect(() => {
        if (uuid) {
            fetchData(`${API_URL}/public/containers/${uuid}`).then((response) => {
                setExhibits(response.childContainers)
            })

        }
    }, [uuid]);

    return (
        <StackContainer>
            <Grid container spacing={2} sx={{alignItems: "center"}}>
                <Grid item>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography component={Link} to="/museums/" variant="caption"
                                    sx={{textTransform: "Capitalize", textDecoration: "none", color: "#000"}}>
                            Μουσεία / Χώροι
                        </Typography>
                        <Typography component={Link} to={`/museums/${state?.uuid}`} variant="caption"
                                    sx={{textTransform: "Capitalize", textDecoration: "none", color: "#000"}}>
                            {state?.label}
                        </Typography>
                        <BreadcrumbItem variant="caption">
                            Εκθέματα
                        </BreadcrumbItem>
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent={'center'}>
                {exhibits.map((exhibit) => {
                    return (
                        <Grid item xs={4} key={exhibit.label}>
                            <Card sx={{display: "flex"}}>
                                <CardActionArea>
                                    <img
                                        height={350}
                                        alt={exhibit.label}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="body1" component="div">
                                            {exhibit.label}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Description
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>

                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>

        </StackContainer>
    )
}
export default ExhibitId

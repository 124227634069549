import StackContainer from "../../components/@core/StackContainer";
import MuseumItem from "../../components/Museums/MuseumItem";
import {useEffect, useState} from "react";
import {fetchData} from "../../utils";
import {API_URL} from "../../config";
import {MUSEUMS_UUID} from "../../enums";

const Museums = () => {

    const [museums, setMuseums] = useState([])

    useEffect(() => {
        fetchData(`${API_URL}/public/containers/${MUSEUMS_UUID}`).then(response => {
            setMuseums(response.childContainers)
        })
    }, []);

    return (
        <StackContainer>
            {museums?.map((museum, index) => {
                return (
                    <MuseumItem key={museum.uuid} item={museum} index={index}/>
                )
            })}
        </StackContainer>
    )

}

export default Museums;
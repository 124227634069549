import introVideo from '../../assets/Videos/Intro/intro.mp4'
import {styled} from "@mui/material";

const VideoContainer = styled("div")(({theme}) => ({
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: theme.zIndex.tooltip,
    backgroundColor: theme.palette.common.black,

}));

const VideoItem = styled("video")(() => ({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    cursor: "pointer",
}));

function Video({setIsPlaying}) {
    return (
        <VideoContainer onClick={() => setIsPlaying(true)}>
            <VideoItem src={introVideo} autoPlay loop muted/>
        </VideoContainer>
    )
}

export default Video

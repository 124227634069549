import {NavLink} from "react-router-dom";

import {Grid, Paper, styled, Typography} from "@mui/material";

import acropolis_logo from "../../../assets/Images/Header/acropolis-logo-150x103.png";
import bgImage from "../../../assets/Images/Header/background-header.jpg";

const ItemLogo = styled(Paper)(({theme}) => ({
    padding: theme.spacing(11),
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundImage: `url(${bgImage})`,
    objectFit: "cover",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "0",

}));

function LogoContainer() {

    return (
        <ItemLogo elevation={12}>
            <Grid
                container
                direction={{xs: "column", lg: "row"}}
                alignItems="center"
                spacing={{xs: 2, md: 3}}
                columns={{xs: 4, sm: 10, md: 12, lg: 12}}
            >
                <Grid item xs={2}>
                    <NavLink to="/">
                        <img src={acropolis_logo} alt="acropolis logo"/>
                    </NavLink>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h2" sx={{
                        fontSize: {xs: "1.2rem", md: "1.7rem", lg: "2.3rem", xl: "2.7rem"}
                    }}>
                        Οι περιπέτειες των γλυπτών της Ακρόπολης
                    </Typography>
                </Grid>
            </Grid>
        </ItemLogo>
    )
}

export default LogoContainer

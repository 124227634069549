import {APP_TOKEN} from "./config";
import axios from "axios";


function handleError(err) {
  if (err.response) {
    const errorMsg = err.response.data.message;
    if (errorMsg) throw new Error(err.response.data.message);
    throw new Error(`Something went wrong. Error code: ${err.request.status}.`);
  }
  throw new Error("Something went wrong.");
}

export const getAxiosDefaultConfig = () => {
  return {
    headers: {
      Authorization: APP_TOKEN,
      "X-TenantID": "acropolis",
    },
  };
};

export const fetchData = (url) =>
  axios
    .get(url, getAxiosDefaultConfig())
    .then((res) => res.data)
    .catch(handleError);

export const postData = (url, data, cancelToken = null) =>
  axios
    .post(url, data, {...getAxiosDefaultConfig(), cancelToken: cancelToken ? cancelToken.token : null})
    .then((res) => res.data)
    .catch(handleError);


export const getPropertyValue = (uuid, item) => {
  if (!item)
    return true
  const property = item?.properties?.find((prop) => prop.propertyUuid === uuid)
  if (Boolean(property)) {
    return property.value
  }
  return null
}

export const getPropertyMultipleValues = (uuid, item) => {
  if (!item)
    return true

  const property = item?.properties?.filter((prop) => prop?.propertyUuid === uuid)
  if (property?.length > 0) {
    return property.map((prop)=>prop.value)
  }
  return null
}
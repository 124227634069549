import {Box, IconButton, Skeleton, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchData, getPropertyMultipleValues, getPropertyValue} from "../../utils";
import {API_URL} from "../../config";
import Carousel from "react-multi-carousel";
import {PlayCircle} from "@mui/icons-material";
import VideoDialog from "./VideoDialog";
import {
  EXHIBIT_URL1_PROPERTY_UUID,
  MEDIA_CONTAINER_TYPE_SLUG,
  STORIES_EMBEDDED_VIDEO,
  STORIES_EMBEDDED_VIDEO_SCHEMA_UUID
} from "../../enums";
import StoriesVideoCard from "../Cards/StoriesVideoCard";


const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 1,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  }
};

const StoriesVideoCards = ({uuid}) => {

  const [videos, setVideos] = useState([])
  const [embeddedVideos,setEmbeddedVideos] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(null)

  useEffect(() => {
      fetchData(`${API_URL}/v2/public/containers/${uuid}`).then(async (response) => {
        setLoading(true)
        const embVids = response?.properties?.filter((prop)=>prop.schemaPropertyUuid === STORIES_EMBEDDED_VIDEO_SCHEMA_UUID)
        if (embVids?.length >0)
          setEmbeddedVideos(embVids)
        fetchData(`${API_URL}/public/containers/${uuid}/children?size=100`).then((childrenResponse) => {
          const media = childrenResponse.content.find((childContainer) => childContainer.type.slug === MEDIA_CONTAINER_TYPE_SLUG)
          if (media?.uuid) {
            fetchData(`${API_URL}/public/containers/${media.uuid}/datastreams?size=100`).then((mediaResponse) => {
              setVideos(mediaResponse.content.filter((datastream) => datastream.bitstreamFile.mimeType === 'video/mp4'))
              setLoading(false)
            }).catch((e) => {
              setLoading(false)
            })
          }
        }).catch((e) => {
          setLoading(false)
        })
      })
    }, [uuid])

    if (loading)
      return <Stack direction={'row'} spacing={3} mt={3.5} alignItems={'center'}>
        {[...Array(3)].map((sk, idx) => {
          return <Skeleton variant={'rectangular'} width={350} height={180}/>
        })}
      </Stack>
    return <Box mt={5}>
      <Carousel
        showDots={videos?.length >= 3}
        arrows={false}
        draggable={true}
        swipeable={true}
        responsive={responsive}
      >
        {videos?.map((video,idx) => {
          return <StoriesVideoCard key = {`video-${idx}`} isEmbedded={false} setSelectedVideo={setSelectedVideo} video={video}/>
        })}
        {embeddedVideos?.map((video,idx) => {
          return <StoriesVideoCard key = {`video-emb-${idx}`} isEmbedded={true} setSelectedVideo={setSelectedVideo} video={video}/>
        })}
      </Carousel>
      <VideoDialog selectedVideo={selectedVideo} handleClose={() => setSelectedVideo(null)}/>
    </Box>
  }


  export default StoriesVideoCards

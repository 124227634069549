import {Container} from "@mui/material";

const Main = ({children}) => {
    return (
        <main>
            <Container maxWidth={"lg"} disableGutters>
                {children}
            </Container>
        </main>

    )
}

export default Main;
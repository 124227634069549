import React, {useCallback, useEffect, useState} from 'react'
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {postData} from "../../utils";
import {API_URL} from "../../config";
import {
  eraSchemaProperties,
  EXHIBIT_CONTAINER_TYPE_UUID,
  SEARCH_ERA,
  SEARCH_MONUMENT,
  SEARCH_MUSEUM,
  SEARCH_TYPE, searchMonumentSchemaProperties, searchMuseumSchemaProperties,
  searchTypeSchemaProperties
} from "../../enums";

function FormIndex({setSearchResults, loading, setLoading}) {

  const [searchValue, setSearchValue] = useState(null)
  const [propertyValueFilters, setPropertyValueFilters] = useState([])
  const [totalElements,setTotalElements] = useState(0)

  const handleSubmit = useCallback(() => {
    setLoading(true)
    const payload = {
      value: searchValue,
      containerTypes: [EXHIBIT_CONTAINER_TYPE_UUID],
      propertyValueFilters: propertyValueFilters

    }
    postData(`${API_URL}/public/containers/search?size=100`, {...payload}).then((response) => {
      setSearchResults(response)
      setTotalElements(response.totalElements)
      setLoading(false)
    }).catch((e) => {
      setLoading(false)
      console.log(e)
    })
  }, [searchValue, propertyValueFilters])

  useEffect(()=>{
    handleSubmit()
  },[])

  const handleClear = () => {
    setSearchValue('')
    setPropertyValueFilters([])
  }

  const buildPropertyValueFilters = useCallback((selectedProperty, value) => {
    const appendSelected = propertyValueFilters.filter((pro) => pro.propertyUuid !== selectedProperty)
    if (value === '' || !value)
      setPropertyValueFilters(appendSelected)
    else
      setPropertyValueFilters(() => [...appendSelected, {
        operator: 'EQUALS',
        logicalOperator: 'AND',
        propertyUuid: selectedProperty,
        propertyValue: `${value}`
      }])
  }, [propertyValueFilters, setPropertyValueFilters])


  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center" xs={12}>
      <Grid item xs={12} textAlign="center">
        <FormControl sx={{width: "100%", textAlign: "center"}} disabled={loading}>
          <OutlinedInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter')
                handleSubmit()
            }}
            startAdornment={<SearchIcon position="start"/>}
            placeholder="Αναζήτηση"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <Box sx={{minWidth: 120}}>
          <FormControl fullWidth disabled={loading}>
            <InputLabel id="type-label" variant={'filled'}>Είδος</InputLabel>
            <Select
              onChange={(e) => {
                buildPropertyValueFilters(SEARCH_TYPE, e.target.value)
              }}
              labelId="type-label"
              id="type"
            >
              <MenuItem key={`searchType-null`} value="">-</MenuItem>
              {searchTypeSchemaProperties.map((item,idx)=>{
                return <MenuItem key={`searchType-${item.value}`} value={item.value}>{item.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box sx={{minWidth: 120}}>
          <FormControl fullWidth disabled={loading}>
            <InputLabel id="momentum-label" variant={'filled'}>Μνημείο</InputLabel>
            <Select
              onChange={(e) => {
                buildPropertyValueFilters(SEARCH_MONUMENT, e.target.value)
              }}
              labelId="momentum-label"
              id="momentum"
            >
              <MenuItem value={''}>-</MenuItem>
              {searchMonumentSchemaProperties.map((item)=>{
                return  <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box sx={{minWidth: 120}}>
          <FormControl fullWidth disabled={loading}>
            <InputLabel id="age-label" variant={'filled'}>Εποχή</InputLabel>
            <Select
              labelId="age-label"
              id="age"
              onChange={(e) => {
                buildPropertyValueFilters(SEARCH_ERA, e.target.value)
              }}
            >
              <MenuItem key={'era-null'} value="">-</MenuItem>
              {eraSchemaProperties.map((item,idx)=>{
                return <MenuItem key={`era-${item.value}`} value={item.value}>{item.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box sx={{minWidth: 120}}>
          <FormControl fullWidth disabled={loading}>
            <InputLabel id="museum-label" variant={'filled'}>Μουσείο/Αρχαιολογικός χώρος</InputLabel>
            <Select
              labelId="museum-label"
              id="museum"
              onChange={(e) => {
                buildPropertyValueFilters(SEARCH_MUSEUM, e.target.value)
              }}
            >
              <MenuItem value="">-</MenuItem>
              {searchMuseumSchemaProperties.map((item)=>{
                return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={2} direction={'row'} justifyContent={'flex-end'}>
          <Button variant={'contained'} sx={{textTransform: 'capitalize'}} onClick={handleClear}
                  disabled={loading || ((searchValue === '' || !searchValue) && propertyValueFilters?.length === 0 )}>Καθαρισμός</Button>
          <Button variant={'outlined'} sx={{textTransform: 'capitalize'}} onClick={handleSubmit}
                  disabled={loading}>Αναζήτηση</Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'body2'}>{`Βρέθηκαν ${totalElements} εκθέματα`}</Typography>
      </Grid>
    </Grid>
  )
}

export default FormIndex

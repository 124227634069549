import HomeIcon from '@mui/icons-material/Home';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import MuseumIcon from '@mui/icons-material/Museum';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SearchIcon from '@mui/icons-material/Search';

export const navigationPages = [
    {label: "Αρχική", path: "/", icon: <HomeIcon/>},
    {label: "Με μια ματιά", path: "/me-mia-matia", icon: <PersonSearchRoundedIcon/>},
    {label: "Μουσεία / Χώροι", path: "/museums", icon: <MuseumIcon/>},
    {label: "Ιστορίες", path: "/stories", icon: <AutoStoriesIcon/>},
    {label: "Ευρετήριο", path: "/evretirio", icon: <SearchIcon/>},
    // {label: "Χρονολόγιο", path: "/xronologio", icon: <TimelineIcon/>},
]

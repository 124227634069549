import React, {useState, useEffect, useMemo} from 'react';
import {Box, IconButton} from "@mui/material";
import {PlayCircle} from "@mui/icons-material";
import axios from 'axios';

function getVideoDetails(url) {
  let videoId;
    if (url?.includes('youtube.com') || url?.includes('youtu.be')) {

      const youtubeMatch = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
      videoId = youtubeMatch ? youtubeMatch[1] : null;
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    } else if (url?.includes('vimeo.com')) {

      const vimeoMatch = url.match(/vimeo\.com\/(?:video\/)?([0-9]+)/);
      videoId = vimeoMatch ? vimeoMatch[1] : null;
      return `https://vumbnail.com/${videoId}.jpg`
    }
    else {
      return 'https://placehold.jp/3d4070/ffffff/300x150.png'
    }
}

const StoriesVideoCard = ({setSelectedVideo,video,isEmbedded}) =>{

  const thumbnailUrl = useMemo(()=>{
    return getVideoDetails(video?.value)
  },[isEmbedded,video?.value])

  if (isEmbedded)
  return <Box style={{height: 300, position: 'relative'}}>
    <Box style={{
      position: "absolute",
      inset: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      flexDirection: "row",
      height: "200px"
    }}>
      <IconButton sx={{bgcolor: '#404261', color: '#eeeeee'}}
                  onClick={() => setSelectedVideo({label: video.property.name, url: video.value, isEmbedded:true})}>
        <PlayCircle/>
      </IconButton>
    </Box>
    <img src={thumbnailUrl} style={{objectFit: 'contain', width: '100%'}}
         height={200} alt={video.label}/>
  </Box>


  return <Box style={{height: 300, position: 'relative'}}>
    <Box style={{
      position: "absolute",
      inset: "0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      flexDirection: "row",
      height: "200px"
    }}>
      <IconButton sx={{bgcolor: '#404261', color: '#eeeeee'}}
                  onClick={() => setSelectedVideo({label: video.label, url: video.bitstreamFile.viewUrl})}>
        <PlayCircle/>
      </IconButton>
    </Box>
    <img src={video.bitstreamFile.thumbnails.mediumUrl} style={{objectFit: 'contain', width: '100%'}}
         height={200} alt={video.label}/>
  </Box>
}


export default StoriesVideoCard

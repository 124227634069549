import {useEffect, useState} from "react";

export function useSessionStorage(initialState, key) {

    const [value, setValue] = useState(() => {
        const storedValue = sessionStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialState;
    });

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value));

        return (() => {
            sessionStorage.removeItem(key);
        })

    }, [value, key]);

    return [value, setValue];
}

import {Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchData} from "../../utils";
import {API_URL} from "../../config";
import ScrollContainer from "react-indiana-drag-scroll";
import {ME_MIA_MATIA_LEGEND_TYPE, ME_MIA_MATIA_YPERSUNOLO_TYPE} from "../../enums";

const QuickLookCard = ({item,hideTitle=false, maxWidth='80vw'}) => {
    const [coverImage, setCoverImage] = useState(null)
    const [legendImage, setLegendImage] = useState(null)
    useEffect(() => {
        if (item.uuid) {
            fetchData(`${API_URL}/public/containers/${item.uuid}`).then((response) => {
                const findCoverImage = response.datastreams.find((datastream) => datastream.type.slug === ME_MIA_MATIA_YPERSUNOLO_TYPE)
                const findLegendImage = response.datastreams.find((datastream) => datastream.type.slug === ME_MIA_MATIA_LEGEND_TYPE)
                findCoverImage && setCoverImage(findCoverImage.bitstreamFile.viewUrl)
                findLegendImage && setLegendImage(findLegendImage.bitstreamFile.viewUrl)
                // setData(response)
            })
        }
    }, [item.uuid])
    return <Stack direction={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
        {!hideTitle && <Typography variant={'h4'}>{item.label}</Typography>}
        <ScrollContainer hideScrollbars={false} style={{textAlign: 'center',maxWidth:maxWidth}}>
            <img src={coverImage} height={200} alt={`cover-${item.uuid}`} />
        </ScrollContainer>
        <img src={legendImage} style={{minWidth: 200, width: 'auto', maxWidth: maxWidth}} alt={`legend-${item.uuid}`}/>
    </Stack>
}

export default QuickLookCard